import React, { useState, useEffect, useCallback } from "react";

const TypewriterEffect = ({ isCompleted, setIsCompleted }) => {
  const textContent = [
    {
      text: "Did You Know? With Teepee:",
      isTitle: true,
    },
    {
      text: "• Teachers can conduct ",
      continuation: [
        { text: "exam-style question practice", isBold: true },
        { text: " with " },
        { text: "instant AI marking and feedback", isBold: true },
        { text: " for students." },
      ],
    },
    {
      text: "• Help students practice ",
      continuation: [
        { text: "coding-style exam questions", isBold: true },
        { text: " with " },
        { text: "instant AI evaluation", isBold: false },
        { text: "." },
      ],
    },
    {
      text: "• Access ",
      continuation: [
        { text: "detailed reports", isBold: false },
        { text: " and " },
        { text: "class analytics", isBold: false },
        { text: " to identify " },
        { text: "knowledge gaps", isBold: false },
        { text: "." },
      ],
    },
    {
      text: "All for just ",
      continuation: [{ text: "£1 per student per subject per year", isBold: true }, { text: "—\n" }, { text: "(just shy of free 😊)", isBold: true }],
    },
  ];

  // Rest of the code stays exactly the same...
  const [displayText, setDisplayText] = useState([]);
  const [currentLine, setCurrentLine] = useState(0);
  const [currentChar, setCurrentChar] = useState(0);
  const [isTyping, setIsTyping] = useState(true);

  const resetTypewriter = useCallback(() => {
    setDisplayText([]);
    setCurrentLine(0);
    setCurrentChar(0);
    setIsTyping(true);
    setIsCompleted(false);
  }, []);

  const getFullLine = (lineData) => {
    let fullText = lineData.text;
    if (lineData.continuation) {
      lineData.continuation.forEach((part) => {
        fullText += part.text;
      });
    }
    return fullText;
  };

  const getCurrentText = (lineData, charCount) => {
    let result = [];
    let remainingChars = charCount;
    let currentPosition = 0;

    // Add initial text
    const baseTextLength = Math.min(remainingChars, lineData.text.length);
    result.push(
      <span key="base" className={lineData.isTitle ? "font-bold" : ""}>
        {lineData.text.slice(0, baseTextLength)}
      </span>
    );
    currentPosition += baseTextLength;
    remainingChars -= baseTextLength;

    // Process continuation if it exists and there are remaining characters
    if (lineData.continuation && remainingChars > 0) {
      lineData.continuation.forEach((part, index) => {
        if (remainingChars <= 0) return;

        const partLength = Math.min(remainingChars, part.text.length);
        const textToShow = part.text.slice(0, partLength);

        result.push(
          <span key={`cont-${index}`} className={part.isBold ? "font-bold" : ""}>
            {textToShow}
          </span>
        );

        remainingChars -= partLength;
        currentPosition += partLength;
      });
    }

    return result;
  };

  useEffect(() => {
    if (!isTyping) return;

    if (currentLine >= textContent.length) {
      setIsTyping(false);
      setIsCompleted(true);
      return;
    }

    const timer = setTimeout(() => {
      const fullLineText = getFullLine(textContent[currentLine]);

      if (currentChar < fullLineText.length) {
        setCurrentChar((prev) => prev + 1);
      } else {
        setCurrentLine((prev) => prev + 1);
        setCurrentChar(0);
      }
    }, 50);

    return () => clearTimeout(timer);
  }, [currentChar, currentLine, isTyping]);

  return (
    <div className="max-w-2xl mx-auto p-6">
      <div className="relative">
        {textContent.map((line, lineIndex) => (
          <div key={lineIndex} className={`relative ${line.isTitle || lineIndex === textContent.length - 1 ? "text-center" : "text-left"}`}>
            {lineIndex === 0 ? (
              <h2 className="text-xl font-bold mb-4 inline-block !text-[28px]">
                {lineIndex < currentLine || isCompleted ? getFullLine(line) : lineIndex === currentLine && getCurrentText(line, currentChar)}
                {!isCompleted && lineIndex === currentLine && (
                  <span className="inline-block w-2 h-5 bg-black animate-pulse ml-1 align-middle">&nbsp;</span>
                )}
              </h2>
            ) : (
              <div className="mb-3">
                <div className={`${lineIndex === 0 || lineIndex === textContent.length - 1 ? "flex justify-center" : "flex justify-start"}`}>
                  <div className={`w-[20px] flex-shrink-0 text-[20px] ${lineIndex === textContent.length - 1 && "hidden"}`}>
                    {(lineIndex < currentLine || isCompleted || (lineIndex === currentLine && currentChar > 0)) && line.text.startsWith("•")
                      ? "•"
                      : ""}
                  </div>
                  <p className={`${lineIndex === textContent.length - 1 ? "text-[24px] mt-[38px]" : "text-[20px]"}  pl-1 whitespace-pre-line`}>
                    {lineIndex < currentLine || isCompleted ? (
                      line.continuation ? (
                        <>
                          <span>{line.text.replace("•", "").trimStart()}</span>
                          {line.continuation.map((part, idx) => (
                            <span key={idx} className={part.isBold ? "font-bold" : ""}>
                              {part.text}
                            </span>
                          ))}
                        </>
                      ) : (
                        line.text.replace("•", "").trimStart()
                      )
                    ) : (
                      lineIndex === currentLine &&
                      getCurrentText(
                        {
                          ...line,
                          text: line.text.replace("•", "").trimStart(),
                        },
                        currentChar
                      )
                    )}
                    {!isCompleted && lineIndex === currentLine && (
                      <span className="inline-block w-2 h-5 bg-black animate-pulse ml-1 align-middle">&nbsp;</span>
                    )}
                  </p>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TypewriterEffect;
