import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import Lottie from "react-lottie";
import * as animationData from "../../../../images/quiz.json";
import LoadingIndicator from "./LoadingIndicator";
import subject_icon from "../../../../images/subject.png";

const TopicSelector = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMounted = useRef(true);
  const { subjectId, subjectName, classroomId } = location.state || {};

  const [topics, setTopics] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]); // List of selected topic objects
  const [loading, setLoading] = useState(false); // Indicates API loading state
  const [isFetchingTopics, setIsFetchingTopics] = useState(true); // Indicates API loading state
  const [error, setError] = useState(null);
  const [sId, setSId] = useState("");
  let topicIdsInCSV = "";
  let topicNamesInCSV = "";
  let payload = {};

  function getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
  }
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleTopic = (topicId, topicName) => {
    setSelectedTopics((prevTopics) => {
      const exists = prevTopics.find((topic) => topic.selectedTopicId === topicId);
      if (exists) {
        // If the topic is already selected, remove it
        return prevTopics.filter((topic) => topic.selectedTopicId !== topicId);
      } else {
        // If the topic is not selected, add it
        return [...prevTopics, { selectedTopicId: topicId, selectedTopicName: topicName }];
      }
    });
  };

  const fetchTopics = async () => {
    const token = localStorage.getItem("access_token");

    if (!process.env.REACT_APP_REST_API_BASE_URL) {
      console.error("Base URL is not defined");
      setError("Configuration error: API base URL is missing.");
      setLoading(false);
      return;
    }

    if (!subjectId) {
      console.error("Subject ID is missing");
      setError("No subject ID provided.");
      setLoading(false);
      return;
    }

    localStorage.setItem("subject_id", subjectId);
    localStorage.setItem("subject_name", subjectName);

    try {
      const response = await fetch(`${process.env.REACT_APP_REST_API_BASE_URL}/get_chapters_mockbot`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          subject_id: subjectId,
          current_date: moment().format("Y-MM-DD HH:mm:ss"),
        }),
      });

      if (!response.ok) throw new Error("Failed to fetch topics");

      const data = await response.json();
      setTimeout(() => {
        setIsFetchingTopics(false);
      }, 1500);
      setSId(data.student_id);

      setTopics(data.topic_list || []);
    } catch (err) {
      console.error("Error fetching topics:", err);
      setError(err.message);
    } finally {
      setLoading(false); // Mark as no longer loading
    }
  };
  const updateLoadingState = async () => {
    setLoading(true);
  };

  const handleSubmitResponse = async () => {
    topicIdsInCSV = selectedTopics.map((topic) => topic.selectedTopicId).join(", ");
    topicNamesInCSV = selectedTopics.map((topic) => topic.selectedTopicName).join(", ");
    fetchQuestions();
  };

  const fetchQuestions = async () => {
    const token = localStorage.getItem("access_token");
    const currentTime = getCurrentDateTime();

    if (!subjectId) {
      console.error("Subject ID is missing");
      setError("No subject ID provided.");
      setTimeout(() => {
        setLoading(false);
      }, 3000);
      return;
    }

    try {
      payload = {
        student_id: sId,
        subject_id: subjectId,
        subject_name: subjectName,
        chapter_id: topicIdsInCSV,
        country: "England",
        country_code: "GB",
        year: "Year 10",
        current_time: currentTime,
        current_date: moment().format("Y-MM-DD HH:mm:ss"),
        predefined_question_answer_ids_csv: "0", // Will send data after the first question
      };

      const response = await fetch(`${process.env.REACT_APP_MOCKBOT_IN_TEEPEE_BASE_URL}/fetch_questions_for_student_teepee`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) throw new Error("Failed to fetch question");
      const text = await response.text();
      const data = text ? JSON.parse(text) : null;

      const timeoutId = setTimeout(() => {
        if (isMounted.current) {
          navigate("/question-page", {
            state: {
              student: { studentId: sId, mockbotStudentId: data.student_id },
              currentQuestion: data.question,
              subject: { subjectName: subjectName, subjectId: subjectId },
              topic: { topicNamesInCSV: topicNamesInCSV, topicIdsInCSV: topicIdsInCSV },
              classroomId: classroomId,
            },
          });
          setLoading(false);
        }
      }, 3000);

      return timeoutId;
    } catch (err) {
      console.error("Error fetching Questions:", err);
      setError(err.message);
    } finally {
      //  setTimeout(() => {
      //   setLoading(false);
      // }, 5000); // Mark as no longer loading
    }
  };
  // useEffect(() => {}, []);

  useEffect(() => {
    fetchTopics();

    // Set the ref to true when the component is mounted
    isMounted.current = true;

    // Cleanup function when the component unmounts
    return () => {
      isMounted.current = false;
    };
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        {" "}
        {/* Full screen centering */}
        <div className="text-center">
          {" "}
          {/* Center text */}
          <div role="status">
            <Lottie options={defaultOptions} height={176} width={176} />
          </div>
          <br></br>
          <div>"Please wait while your quiz is being prepared..."</div>
        </div>
      </div>
    );
  }

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }

  // if (isFetchingTopics) {
  //   return <LoadingSpinner />;
  // }

  return (
    <>
      {/* <Sidebar /> */}
      {isFetchingTopics ? (
        <div>
          <LoadingIndicator text={"Please wait while we are loading your topics"} />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center gap-4 md:mx-800 lg:mx-16 xl:mx-32 mt-[30px]">
          <div className="flex gap-2 md:ml-[68px] sm:ml-[4px] mt-4 mb-2 justify-start w-full">
            <img className="h-[24px] w-[24px]" src={subject_icon} alt="" />
            <span
              className="text-[#322E33] opacity-80"
              style={{
                fontFamily: "Roboto",
                fontSize: "20px",
                lineHeight: "24px",
              }}
            >
              {subjectName}
            </span>
          </div>
          <div className="w-[80%] float-left mb-[20px]">
            <h1
              style={{
                fontFamily: "Roboto",
                fontWeight: "800",
                fontSize: "24px",
                lineHeight: "32px",
              }}
            >
              Choose Topic
              <sub
                style={{
                  fontFamily: "Roboto",
                  fontWeight: "600",
                  fontSize: "10px",
                  lineHeight: "16px",
                  letterSpacing: "0.5px",
                  color: "#9F9F9F",
                  marginLeft: "8px",
                }}
              >
                (Minimum: 01 Topic)
              </sub>
            </h1>
          </div>

          <div className="flex flex-wrap justify-start gap-3 w-[80%]">
            {topics
              .sort((a, b) => a.chapter_name.localeCompare(b.chapter_name))
              .map((topic) => (
                <button
                  className="px-[24px] py-[10px] rounded-full"
                  style={{
                    border: selectedTopics.some((t) => t.selectedTopicId === topic.chapter_id) ? "" : "1.5px solid #6750A4",
                    color: selectedTopics.some((t) => t.selectedTopicId === topic.chapter_id) ? "white" : "#6750A4",
                    backgroundColor: selectedTopics.some((t) => t.selectedTopicId === topic.chapter_id) ? "#6750A4" : "",
                  }}
                  key={topic.chapter_id}
                  onClick={() => handleTopic(topic.chapter_id, topic.chapter_name)}
                >
                  {topic.chapter_name}
                </button>
              ))}
          </div>

          <div className="mt-[24px] justify-center">
            <p
              className="mb-[24px]"
              style={{
                fontFamily: "Roboto",
                fontWeight: "600",
                textAlign: "center",
              }}
            ></p>
          </div>

          <div className="flex-col mt-[12px] w-[80%] mb-[24px]">
            <button
              className=" float-left py-[10px] px-[24px]"
              style={{
                border: "1.5px solid #6750A4",
                color: "#6750A4",
                borderRadius: "4px",
                gap: "8px",
                // opacity: "0.53",
              }}
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </button>
            <button
              className="float-right py-[10px] px-[24px]"
              style={{
                border: selectedTopics.length > 0 ? "1.5px solid #6750A4" : "#535255",
                backgroundColor: selectedTopics.length > 0 ? "#6750A4" : "#535255",
                opacity: selectedTopics.length > 0 ? "1" : "0.51",
                borderRadius: "4px",
                gap: "8px",
                color: "white",
              }}
              disabled={selectedTopics.length === 0}
              onClick={() => {
                updateLoadingState();
                handleSubmitResponse();
              }}
            >
              Start Practising
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default TopicSelector;
