import React from "react";
import DashboardLabel from "./DashboardLabel";
import ArrowForward from "../../../images/arrow_forward.svg";
import Book from "../../../images/book.svg";
import Calendar from "../../../images/calendar.svg";

const WelcomeComponent = ({ haveLesson, onClickSchedule, handleClickPrior }) => {
  return (
    <div>
      <div className="w-full flex sm:flex-col md:flex-row   justify-between">
        {haveLesson !== 1 ? (
          <div className="mb-8 ">
            <DashboardLabel text="Welcome" />
            <div className="ml-6">
              <div className="p-2 text-[16px]">Set up spaced retrieval quizzes for your students with ease:</div>
              <ul className="space-y-2 text-[16px] list-disc list-inside">
                <li>
                  <b>Tell us</b> what topics you've covered or plan to cover.
                </li>
                <li>
                  <b>Invite your students</b> to join the class—quizzes will be sent to them automatically.
                </li>
                <li>
                  <b>We'll generate quizzes</b> that reinforce learning at the right time.
                </li>
                <li>
                  <b>Monitor class and student progress</b> to identify areas needing extra attention.
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div className="mb-8">
            <DashboardLabel text="Welcome Back!" />
            <div className="ml-6 mt-[29px]">
              <div className="p-2 text-[16px]">Keep students on track by updating past lessons or scheduling upcoming topics:</div>
              <ul className="space-y-2 text-[16px] list-disc list-inside">
                <li>
                  <b>Keep Your Schedule Up to Date:</b> Inform us of what you’ve covered or plan to cover.
                </li>
                <li>
                  <b>Monitor Progress:</b> Track student performance and adjust retrieval as needed.{" "}
                  {/* <span className="text-[10px] font-bold">[Learn more about effectively tracking student retrieval practice]</span> */}
                </li>
              </ul>
            </div>
          </div>
        )}

        <div className="w-[311px] bg-[#F7F2F9] rounded-lg p-3 float-right h-fit	">
          <h3 className="font-bold text-[16px]" style={{ fontFamily: "Roboto" }}>
            Want to learn more?
          </h3>
          <ul className="list-disc   space-y-2 text-[14px] pl-[1.5rem]" style={{ fontFamily: "Roboto" }}>
            <li>
              <a
                href="https://teepee.ai/blog/what-is-spaced-retrieval-and-why-its-important/"
                target="_blank"
                className="hover:underline  text-[#6750A4]"
              >
                Learn about spaced retrieval
              </a>
            </li>
            <li>
              <a
                href="https://teepee.ai/blog/guide-to-setting-up-retrieval-practice-on-teepee/"
                target="_blank"
                className="hover:underline  text-[#6750A4]"
              >
                Set Up Retrieval Practice for Your Students
              </a>
            </li>
            <li>
              <a href="https://teepee.ai/blog/joining-teepee-for-retrieval-practice/" target="_blank" className="hover:underline  text-[#6750A4]">
                How Students Can Join Teepee for Retrieval Practice
              </a>
            </li>
          </ul>
        </div>
      </div>
      {(haveLesson === 1 || haveLesson !== 1) && <DashboardLabel text="Start Here" />}
      <div className={`md:flex md:space-x-8 sm:space-x-0 md:p-4 sm:py-2 ${haveLesson === 1 && "sm:w-[100%] md:w-[50%]"} w-[90%]`}>
        {haveLesson !== 1 && (
          <div
            className="bg-gradient-to-b from-[#7088EE] to-[#818DC1] rounded-2xl pt-4 pl-6 relative md:w-[50%] sm:w-[100%]"
            onClick={handleClickPrior}
            style={{ cursor: "pointer" }}
          >
            <div className="text-left">
              <h3 className="text-[22px] font-black	 text-white">Covered So Far</h3>
              <p className="mt-2 text-white md:text-[0.95vw] md:w-[70%] sm:w-[90%]">
                Share the topics you've already covered to tailor quizzes to your students' learning.
              </p>
              <button className="mt-6 py-3 text-white text-[14px] font-medium flex item-center w-auto">
                <div>Choose Topics</div>

                <img className="ml-2" src={ArrowForward} />
              </button>
            </div>

            <div className="absolute bottom-0 right-0">
              <img src={Book} alt="Book Icon" className="sm:hidden md:block " />{" "}
            </div>
          </div>
        )}
        {haveLesson !== 1 && <div className="text-2xl font-semibold text-gray-500 flex justify-center items-center">OR</div>}

        <div
          className={`bg-[#EF95BA] rounded-2xl pt-4 pl-6 relative  ${haveLesson !== 1 && "md:w-[50%] sm:w-[100%]"}`}
          onClick={onClickSchedule}
          style={{ cursor: "pointer" }}
        >
          <div className="text-left">
            <h3 className="text-[22px] font-black	 text-white">Plan to Cover</h3>
            <p className="mt-2 text-white md:text-[0.95vw]  md:w-[70%] sm:w-[90%]">
              Plan your teaching schedule, and we’ll sync quizzes to align with your timeline.{" "}
            </p>
            <button className="mt-6 py-3 text-white text-[14px] font-medium flex w-full item-center w-auto">
              <div>Set Schedule</div>

              <img className="ml-2" src={ArrowForward} />
            </button>
          </div>
          <div className="absolute bottom-0 right-0 sm:hidden md:block">
            {" "}
            <img src={Calendar} alt="Calendar Icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeComponent;
