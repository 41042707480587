const apiConfig = {
  baseURL: process.env.REACT_APP_REST_API_BASE_URL, // The base URL for all environments
  endpoints: {
    getSuperTopic: "/get_super_topic_for_lesson",
    getTopic: "/get_topic_for_lesson",
    getSubTopic: "/get_sub_topic_for_lesson",
    getAreas: "/get_areas_for_lesson",
    getLeaderboard: "/view_leaderboard",
    getQuizzes: "/get_quizzes",
    getSharedClassroom: "/fetch_shared_clases",
    getQuizDataForCopy: "/get_quiz_data_for_copy",
    getStudentDataInQuiz: "/check_student_data_in_quiz",
    RemoveQuiz: "/remove_quiz",
    getSubscriptionTabData: "/get_subscription_tab_data",
    createOrRetrieveCustomer: "/create_or_retrieve_customer",
    createCustomerPortalSession: "/create_customer_portal_session",

    // Mockbot Apis.
    // fetch_questions_for_student_teepee: "/fetch_questions_for_student_teepee",
    // submit_feedback_after_response_teepee: "/submit_feedback_after_response_teepee",
    // submit_question_level_feedback_teepee: "/submit_question_level_feedback_teepee",
    // submit_response_authentic_student_teepee: "/submit_response_authentic_student_teepee",
    // evaluate_question_for_student_teepee: "/evaluate_question_for_student_teepee",
  },
};
export default apiConfig;
