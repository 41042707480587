import React, { useEffect, useState } from "react";
import verify from "../../../../images/green-tick.png";
import { useNavigate } from "react-router-dom";
import left_icon from "../../../../images/arrow_left_alt.png";
import FeedbackDisplay from "../../../widgets/FeedbackDisplay";
import CodeEditor from "../../../widgets/CodeEditor";
import feedback from "../../../../images/feedback.svg";

// import ReactGA from "react-ga4";
const SessionSummary = ({ attempts, onRestart, onEmail, mockbotStudentId, subject, classroomId }) => {
  const navigate = useNavigate();
  const email = localStorage.getItem("email_address");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [text, setText] = useState("");
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setText("");
  };

  //
  const totalObtainedMarks = attempts.reduce(
    // (acc, attempt) => acc + extractMarks(attempt.ai_feedback),
    (acc, attempt) => acc + parseInt(attempt.obtained_marks),
    0
  );

  const handleSubmitFeedback = async () => {
    const token = localStorage.getItem("access_token");
    var email = localStorage.getItem("email_address");
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_MOCKBOT_IN_TEEPEE_BASE_URL}/submit_feedback_after_response_teepee`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          student_id: mockbotStudentId,
          email: email,
          text: text,
        }),
      });
      if (response.ok) {
        setLoading(false);
        setIsModalOpen(false);
        setText("");
        setFeedbackSubmitted(true);
        // ReactGA.event("Feedback Submitted button", {
        //   feedback_submitted_button: "Feedback Submitted",
        // });
      } else {
        alert("feedback sending failed");
        setText("");
        setFeedbackSubmitted(false);
      }
    } catch (error) {
      alert(error.message);
    }
  };
  const handleSubmitResponse = async () => {
    window.location.reload();
    navigate(-1);
  };

  function stripHTMLTags(str) {
    return str.replace(/<p>/g, "");
  }

  console.log("DATA IN SUMMARY: ", attempts);
  return (
    <>
      <div className="bg-[#F2F2F2] py-4">
        <span
          onClick={() => {
            // navigate("/select-topic", { state: { subjectId: subject.subjectId, subjectName: subject.subjectName } });
            navigate("/student-view-classroom", {
              state: {
                studentClassroomData: {
                  classroom_id: classroomId,
                },
              },
            });
          }}
          className="md:ml-[68px] cursor-pointer flex sm:ml-[4px]  gap-2 font-semibold text-[#444780]"
          style={{
            fontFamily: "Roboto",
            fontSize: "14px",
            lineHeight: "20px",
          }}
        >
          <img src={left_icon} alt="" /> Back
        </span>
      </div>
      <div className="flex bg-[#F2F2F2] justify-center items-center min-h-screen">
        <div className="bg-white max-w-2xl mx-auto p-4 rounded-[12px]">
          <h2 className="text-xl font-semibold text-center">Session Summary</h2>
          <br />

          <br />
          {attempts.length > 0 ? (
            attempts.map((attempt, index) => (
              <div key={index} className="my-2 p-2 border-b">
                <div style={{ display: index === 0 ? "" : "none" }} className="flex flex-row  justify-between items-center">
                  {" "}
                  <p>
                    <b>Your Email:</b> {email}
                  </p>
                  <p className="float-right">
                    <b>Marks Obtained :</b> {totalObtainedMarks}/{attempts.reduce((acc, attempt) => acc + attempt.question_marks, 0)}
                  </p>
                </div>
                <div className="w-full mt-[24px]" style={{ display: "flex", alignItems: "flex-start" }}>
                  <div style={{ flex: 1 }}>
                    {attempt.have_code === 1 ? (
                      <p
                        className="answer-paragraph md:whitespace-break-spaces "
                        style={{
                          fontSize: "16px",
                          fontFamily: "Roboto",
                          color: "#49454F",
                        }}
                      >
                        <p
                          dangerouslySetInnerHTML={{
                            __html: `<div><span class="question" style=" color: #212529"><b>Question</b>&nbsp;${index + 1}:</span> ${stripHTMLTags(
                              attempt.question.split("##code")[0]
                            )}</div>`,
                          }}
                        />
                      </p>
                    ) : (
                      <p style={{ margin: 0, whiteSpace: "pre-line" }}>
                        <b>Question {index + 1}:</b> {attempt.question.replace(/<[^>]*>/g, "")}
                      </p>
                    )}
                  </div>
                  <div style={{ marginLeft: "20px", alignSelf: "start" }}>
                    <span className="">
                      <b>[</b> <b>Marks :</b> {attempt.obtained_marks}/{attempt.question_marks} <b>]</b>
                    </span>
                  </div>
                </div>

                <p className="mt-[4px]">
                  <b>Your Answer:</b>{" "}
                  {attempt.have_code === 1 ? (
                    <CodeEditor
                      language="javascript" // Replace with the language you want or leave empty for generic text
                      theme="monokai"
                      id={"" + index}
                      readOnly={true}
                      name="code"
                      value={attempt.answer} // Pass the current value for controlled input
                    />
                  ) : (
                    attempt.answer
                  )}
                </p>
                <br />
                <p>
                  <b>Feedback:</b> {attempt.have_code === 1 ? <FeedbackDisplay feedback={JSON.parse(attempt.ai_feedback)} /> : attempt.ai_feedback}
                </p>
                <br />
                {/* <Accordion>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                  <Typography>
                  <b>Actual Answer:</b>
                  </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                  <Typography>
                    <span style={{ whiteSpace: "pre-line" }}>{attempt.actual_answer}</span>
                  </Typography>
                </AccordionDetails>
              </Accordion> */}
              </div>
            ))
          ) : (
            <p className="text-center">No questions were attempted.</p>
          )}
          {isModalOpen && (
            <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-10">
              <div className="bg-white rounded-lg w-96">
                <div className="flex flex-col items-center p-4">
                  <div className="flex items-center w-full justify-center mb-2">
                    <div className="flex flex-col items-center gap-2">
                      <img className="mt-1 mx-auto" src={feedback} alt="tdr" />
                      <div className="text-center">Let us know how we can improve Teepee.ai’s analysis.</div>
                    </div>
                  </div>
                  <textarea
                    className="w-full h-24 p-2 mt-2 mb-4 text-sm text-gray-800 bg-gray-100 rounded-lg focus:outline-none focus:shadow-outline resize-none"
                    placeholder="Type Here."
                    value={text}
                    onChange={(e) => {
                      setText(e.target.value);
                    }}
                  />
                  <div className="flex justify-end w-full">
                    <button
                      className="px-4 py-2 mr-3 text-sm text-[#6750A4] background-transparent rounded-lg hover:bg-gray-200 focus:outline-none"
                      onClick={toggleModal}
                    >
                      CANCEL
                    </button>
                    <button
                      className="flex px-4 py-2 text-sm text-[#6750A4] background-[transparent] rounded-lg hover:bg-gray-200 focus:outline-none"
                      onClick={handleSubmitFeedback}
                      disabled={text.length === 0}
                    >
                      SUBMIT
                      {loading && (
                        <svg
                          className="animate-spin -mr-1 ml-3 h-5 w-5 text-[#6750A4]"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {feedbackSubmitted && (
            <div className="flex justify-center mb-[20px] gap-3">
              <b>Feedback Submitted</b> <img src={verify} alt="verify" />
            </div>
          )}
          <div className="flex justify-center gap-3 mt-4 flex-col">
            <button
              className="bg-[#444780] w-full hover:bg-[#444780] hover:opacity-[0.7]  text-white font-bold py-2 px-4 rounded"
              onClick={handleSubmitResponse}
            >
              Start Over
            </button>
            {!feedbackSubmitted && (
              <button
                className="bg-[white] w-full hover:opacity-[0.7] text-[#444780] font-bold py-2 px-4  border-[1px] border-[#444780] rounded"
                onClick={toggleModal}
              >
                Share Feedback
              </button>
            )}

            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" style={{ display: "none" }} onClick={onEmail}>
              Email Summary
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SessionSummary;
