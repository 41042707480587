import React, { useState } from "react";
import subject_icon from "../../../images/menu_book.svg";
import quiz_icon from "../../../images/assignment.svg";

function StudentDoTheseCard(props) {
  const { doThese, quizType, viewQuiz } = props;

  const [isSliding, setIsSliding] = useState(false);

  const handleClick = (item) => {
    if (!isSliding) {
      viewQuiz(item, 1);
    }
  };

  return (
    <div className="mt-1">
      <div className="flex overflow-x-auto space-x-4 snap-x snap-mandatory ">
        {/* Mapping through the doThese array */}
        {doThese.map((item, index) => (
          <div
            key={index}
            className={`${
              quizType === 1 ? "bg-gradient-to-r from-[#937CFE] to-[#B58DFE]" : "bg-gradient-to-r from-[#91AEF4] to-[#95C6F4]"
            } text-white p-6 rounded-lg shadow-md w-[300px] flex-none`}
            onClick={() => handleClick(item)}
            style={{
              scrollSnapAlign: "start", // Ensure each item snaps properly
              marginRight: index === doThese.length - 1 ? "5%" : "0", // Add margin to the last item to create the partial card effect
            }}
          >
            <div className="flex justify-between items-start mb-4">
              <div className="text-lg font-bold overflow-hidden text-ellipsis whitespace-nowrap w-[150px]">
                {quizType === 2 ? item.student_quiz_title : item.quiz_title}
              </div>

              <div style={{ fontFamily: "Roboto" }} className="bg-white text-[#424242] text-[14px] leading-[16px]  font-bold px-3 py-1 rounded-[4px]">
                {quizType === 1 ? (item.quiz_type === 2 ? "Mini-mock" : item.quiz_type === 1 ? "Formative" : "Exam Style") : "Retrieval"}
              </div>
            </div>
            <div className="flex items-center mb-4">
              <img src={subject_icon} alt="sub Icon" className="w-5 h-5 mr-2" />
              <span style={{ fontFamily: "Roboto" }} className="text-[14px] leading-[20px] font-medium">
                {item.subject_name}
              </span>
            </div>
            <div className="flex items-center mb-6">
              <img src={quiz_icon} alt="Quiz Icon" className="w-5 h-5 mr-2" />
              <span style={{ fontFamily: "Roboto" }} className="text-[14px] leading-[20px] font-medium">
                {quizType === 1 ? "Exam style Questions" : "Retrieval Quiz"}
              </span>
            </div>
            <button className="bg-white text-[16px] leading-[20px] text-[#424242] font-bold py-2 px-4 w-full rounded-md hover:bg-gray-200">
              {quizType === 1 ? "START TASK" : "START QUIZ"}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default StudentDoTheseCard;
